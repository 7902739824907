.counter {
  width: 33%;
}

.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 10px 12px;
  border: 1px solid rgb(220, 220, 220);
}

.count p {
  margin: 0;
  padding: 0;
  font-size: 14.5px;
}

.icon {
  font-size: 20px;
  cursor: pointer;
}

.ageGroup p {
  font-size: 14.5px;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
}

.ageGroup span {
  color: rgb(180, 180, 180);
}

@media screen and (max-width: 991px) {
  .counter {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .ageGroup {
    width: 50%;
  }
  .ageGroup p {
    text-align: left;
  }
  .count {
    width: 50%;
  }
}
