.container {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}
