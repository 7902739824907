.Img {
  width: 100%;
}

.IconCard {
  width: 32%;
  text-align: center;
}

.IconCard h3 {
  font-size: 22px;
  margin: 15px 0;
  padding: 0;
  font-weight: 500;
}

.Icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin: 0 auto;
}

.Icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

p {
  font-weight: 300;
}

@media only screen and (max-width: 991px) {
  .Section {
    padding: 100px 0;
  }
  .IconCard {
    width: 100%;
    margin-bottom: 50px;
  }
  .IconCard:last-of-type {
    margin-bottom: 0px;
  }
  .IconCard h3 {
    margin: 20px 0 10px 0;
  }
}
