.Header {
  --webkit-box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 1);
  background-color: white;
  border-bottom: 1px solid rgb(230, 230, 230);
  z-index: 0;
}

.Nav {
  position: static;
  padding: 5px 0;
}

.LogoDiv {
  width: 20%;
  padding: 0;
}

.NavLinks {
  width: auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Logo {
  width: 100%;
}

.NavLink {
  transition-duration: 0.3s;
  padding: 10px 7.5px;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.NavLink:hover {
  color: var(--primary-color);
}

.CTA {
  font-size: 13px;
  font-weight: 500;
  color: white;
  background-color: var(--primary-color);
  padding: 10px 25px;
  border-radius: 3px;
  text-transform: uppercase;
  transition-duration: 0.3s;
}

.CTA:hover {
  background-color: var(--primary-color-dark);
}

.Currency {
  margin-right: 15px;
  border: none;
  outline: none;
}

@media only screen and (max-width: 991px) {
  .Nav {
    display: none;
  }
}
