.Nav {
  display: none;
}

@media only screen and (max-width: 991px) {
  .Nav {
    display: block;
    padding: 15px 0;
    border-bottom: 1px solid rgb(220, 220, 220);
  }
  .MenuContainer {
    width: 15%;
    margin: auto 0;
    background-color: transparent;
    text-align: right;
    padding: 0;
    border: none;
  }
  .MenuContainer > * {
    font-size: 25px !important;
  }
  .LogoContainer {
    width: 60%;
  }
  .Logo {
    width: 100%;
  }
  .MenuList {
    width: 90%;
    margin: 0 auto;
    position: absolute;
    left: 0%;
    right: 0%;
    background-color: white;
    border: 1px solid rgb(150, 150, 150);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
  }
  .MenuList:nth-last-child() {
    margin-bottom: 0px;
  }
  .MenuItem {
    padding: 10px 20px;
    text-align: center;
    transition-duration: 0.3s;
    color: black;
  }
  .MenuItem:nth-of-type(3) {
    margin-bottom: 0;
  }
  .MenuItem:hover {
    background-color: rgb(242, 242, 242);
  }
}
