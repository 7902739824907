.title {
  text-align: center;
  color: var(--primary-color-dark);
  font-size: 80px;
  font-weight: 700;
}

.subTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

.text {
  text-align: center;
}
