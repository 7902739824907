/* src/Calendar.module.css */
.calendar {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Reduced the max width for compactness */
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Added a shadow for depth */
  overflow: hidden;
  z-index: 1000;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 20px; /* Adjusted padding for better space usage */
  background-color: var(--primary-color); /* Background color for the header */
  color: white !important;
  font-weight: bold;
}

.col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  cursor: pointer;
  font-size: 1.2rem; /* Slightly increased icon size for better visibility */
  color: white;
}

.days {
  display: flex;
  width: 100%;
  padding: 10px 0;
  background-color: #f1f1f1; /* Light background for the days row */
  font-weight: bold;
}

.colCenter {
  flex: 1;
  text-align: center;
  font-size: 0.9rem; /* Adjusted for a better legibility */
  color: #333;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

.cell {
  flex: 1;
  min-height: 50px; /* Keeps the cell height uniform */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem; /* Ensures dates are easy to read */
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.number {
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240); /* Ensure no default background */
  transition: background-color 0.2s ease, color 0.2s ease;
}

.number:hover {
  background-color: var(--primary-color);
  color: white;
}

.disabled .number {
  color: #aaa;
  background-color: white !important;
}

.disabled:hover .number {
  cursor: auto;
}

@media screen and (max-width: 991px) {
  .calendar {
    width: 100%;
    max-width: 350px;
    left: 0;
    right: 0;
  }

  .header {
    padding: 8px 16px; /* Adjusted header padding for smaller screens */
  }

  .colCenter {
    font-size: 0.8rem; /* Reduced font size slightly for smaller screens */
  }

  .cell {
    font-size: 0.8rem; /* Adjusted font size for better readability */
    min-height: 50px; /* Reduced height for smaller screens */
  }

  .number {
    width: 40px; /* Reduced size for smaller screens */
    height: 40px; /* Reduced size for smaller screens */
  }
}
