.SelectDiv {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1.5px solid rgb(220, 220, 220);
  padding: 0 15px;
  border-radius: 5px;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
}

.Icon {
  font-size: 16px;
}

.Input {
  width: 100%;
  padding: 12px 15px;
  padding-right: 0;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14.5px;
  transition: border-color 0.3s ease;
}

.Input:focus {
  outline: none;
}

.List {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  padding-left: 0;
  overflow-y: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
}

.List li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  list-style-type: none;
  font-size: 14px;
}

.List li:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.Text {
  color: #666;
  text-align: center;
  padding: 10px;
}
