.footerContainer {
  background-color: var(--primary-color-dark);
  border-radius: 5px 5px 5px 5px;
  padding: 30px;
}

/* Footer links */

.legalLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Partner */

.partnerImgs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid rgb(240, 240, 240);
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.partnerIcon {
  color: white;
  font-size: 40px;
}

/* WhatsApp Icon */

.WhatsAppIcon {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: #25d366;
  text-align: center;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 50px;
  -webkit-box-shadow: 0px 0px 25px -3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 25px -3px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 25px -3px rgba(0, 0, 0, 1);
}

.WhatsAppIcon > * {
  color: white;
  margin: 0 auto;
  font-size: 45px !important;
}

.copyrightText {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgb(200, 200, 200);
  text-align: center;
  color: white;
  font-weight: 300;
}

@media screen and (max-width: 991px) {
  .partnerIcon {
    color: white;
    height: 60px;
    width: 60px;
  }

  .WhatsAppIcon {
    right: 40px;
    bottom: 40px;
  }
  .copyrightText {
    font-size: 13px;
  }
}
