.bookingContainer {
  background-color: white;
  border-radius: 5px;
}

.menu {
  width: 100%;
  background-color: rgb(250, 250, 250);
  margin-bottom: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.menuItem {
  width: 33%;
  padding: 10px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuItem:nth-of-type(3) {
  border-right: none;
}

.menuItem p {
  font-size: 14px;
}

.stepNum {
  width: 30px;
  height: 30px;
  background-color: var(--primary-color);
  border-radius: 100px;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.active {
  border-bottom: 3px solid var(--primary-color);
  background-color: rgb(240, 240, 240);
  border-radius: 5px 5px 0 0;
}

.active p {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  .bookingContainer {
    padding: 50px 20px;
  }
  .menu {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .menuItem {
    min-width: fit-content;
  }
}
