.HeroSection {
  padding: 75px 0;
}

.HeroHeading {
  color: black;
  font-size: 40px;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 600 !important;
}

.Price {
  color: white;
  background-color: var(--primary-color);
  padding: 0px 10px;
  border-radius: 5px;
}

.HeroText {
  color: rgb(50, 50, 50);
  font-size: 19px;
  margin: 30px 0;
  font-weight: 400;
}

/* Form Content */

.form {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 40px 5px rgba(220, 220, 220, 1);
  -moz-box-shadow: 0px 0px 40px 5px rgba(220, 220, 220, 1);
  box-shadow: 0px 0px 40px 5px rgba(220, 220, 220, 1);
}

.iconContainer {
  background-color: rgb(245, 245, 245);
  margin-bottom: 30px;
  border-radius: 100px;
  overflow: hidden;
  transition-duration: 0.3s;
}

.iconContainer:hover {
  background-color: rgb(235, 235, 235);
}

.iconWithText {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  padding: 10px 0;
  cursor: pointer;
  border-radius: 100px;
}

.active {
  background-color: var(--primary-color);
}

.active p {
  color: white;
}

.active .icon {
  background-color: white;
  color: var(--primary-color);
}

.iconWithText p {
  font-size: 16px;
  font-weight: 400;
}

.icon {
  background-color: var(--primary-color);
  color: white;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 100px;
  margin-right: 10px;
}

@media screen and (max-width: 991px) {
  .HeroSection {
    padding: 50px 0 100px 0 !important;
  }
  .Content {
    margin-bottom: 50px;
  }
  .HeroHeading {
    font-size: 34px;
    text-align: left;
    font-weight: 700;
  }
  .HeroText {
    font-size: 16px;
    text-align: left;
    margin: 30px 0;
  }
  .form {
    width: 95%;
    margin: 0 auto;
    padding: 40px 30px;
  }
}
