.accordion {
  padding-bottom: 20px;
  background-color: transparent;
}

.questionDiv {
  display: flex;
  align-items: center;
  color: black;
  background-color: white;
  padding: 15px 20px;
  border-radius: 10px;
  transition-duration: 0.3s;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
  box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
}

.questionDiv:hover {
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px 2px rgba(220, 220, 220, 1);
  -moz-box-shadow: 0px 0px 15px 2px rgba(220, 220, 220, 1);
  box-shadow: 0px 0px 15px 2px rgba(220, 220, 220, 1);
}

.questionDiv .question {
  width: 80%;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
}

.Active {
  color: var(--primary-color);
  font-weight: 700 !important;
}

.icon {
  width: fit-content;
  margin: 0 0 0 auto;
}

.Answer {
  padding: 0 10px;
  transition-duration: 0.3s;
  color: rgb(50, 50, 50);
  font-size: 17px;
}

.Hidden {
  height: 0;
  overflow: hidden;
}

.Show {
  padding: 20px 30px 0 30px;
}

@media screen and (max-width: 991px) {
  .Question,
  .Answer {
    font-size: 16px;
  }
}
