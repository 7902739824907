.Label {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color-dark);
  text-transform: uppercase;
  font-weight: 600;
}

.Required {
  color: red;
  margin-right: 5px;
}

.Optional {
  margin-left: 2.5px;
  color: rgb(150, 150, 150);
  font-weight: 400;
}
