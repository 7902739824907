.skeletonContainer {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.skeletonHeader,
.skeletonBody,
.skeletonFooter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skeletonImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeletonDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.skeletonTextShort,
.skeletonTextLong {
  height: 12px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
  border-radius: 4px;
}

.skeletonTextShort {
  width: 50%;
}

.skeletonTextLong {
  width: 100%;
}

.skeletonBody {
  flex-direction: column;
  gap: 8px;
}

.skeletonDetail {
  width: 100%;
  height: 16px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
  border-radius: 4px;
}

.skeletonPrice {
  width: 30%;
  height: 20px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
  border-radius: 4px;
}

.skeletonButton {
  width: 100px;
  height: 36px;
  background: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out;
  border-radius: 20px;
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}
