.SelectDiv {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1.5px solid rgb(220, 220, 220);
  padding: 0px 15px;
  border-radius: 5px;
  transition-duration: 0.3s;
  position: relative;
  margin: 0;
}

.Icon {
  font-size: 16px;
  margin: 0;
}

.Input {
  width: 100%;
  height: 100%;
  padding: 12px 15px;
  padding-right: 0;
  background-color: transparent;
  border: none;
  outline: none;
  overflow: hidden;
}
