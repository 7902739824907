.container {
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
  }
}
