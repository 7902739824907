.container {
  margin-bottom: 75px;
}

.subtitle {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
}

.title {
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
}

p.secondary {
  color: white;
}

h2.secondary {
  color: white !important;
}

@media only screen and (max-width: 991px) {
  .subtitle {
    font-size: 13px;
  }
  .title {
    font-size: 32px;
  }
}
