.container {
  background-color: white;
  padding: 50px;
  border-radius: 5px;

  -webkit-box-shadow: 0px 0px 10px 2px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(230, 230, 230, 1);
  box-shadow: 0px 0px 10px 2px rgba(230, 230, 230, 1);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 50px;
}

@media only screen and (max-width: 991px) {
  .container {
    padding: 60px 10px;
  }

  .img {
    margin-top: 50px;
  }
}
