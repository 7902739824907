.testimonialCard {
  width: 100%;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding: 30px 30px;
}

.secondary {
  background-color: white;
}

.Title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.ImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ImageContainer img {
  width: 60px;
  height: 60px;
  background-color: black;
  border-radius: 100px;
  object-fit: cover;
  object-position: center;
  border: 0px solid transparent;
  margin: 0 auto;
}

.Author {
  text-align: center;
  color: var(--primary-color);
  margin: 10px 0;
  font-weight: 600;
}

.Content {
  text-align: center;
}

.Content p {
  font-size: 16px;
  color: rgb(0, 0, 0);
  font-weight: 300;
  margin: 20px 0;
}

@media only screen and (max-width: 991px) {
  .testimonialCard {
    width: 100%;
    margin-bottom: 20px;
  }
}
