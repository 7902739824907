.container {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 40px;
  padding: 20px 20px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
  -moz-box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
  box-shadow: 0px 0px 10px 2px rgba(240, 240, 240, 1);
}

.flightDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: white;
  padding: 20px 0;
  margin-bottom: 10px;
}

.flightDetail:nth-of-type(2) {
  border-top: 1px solid rgb(200, 200, 200);
  margin-bottom: 0px;
}

.airlineImgDiv {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.airlineImgDiv img {
  width: 100%;
}

.departureData,
.returnData {
  width: fit-content;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

.departureData span,
.returnData span {
  width: fit-content;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.duration {
  width: fit-content;
  height: fit-content;
  text-align: center;
  color: rgb(150, 150, 150);
  font-size: 14px;
  line-height: 1.2;
}

.price {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.expanded {
  border-bottom: 1px solid #ddd;
}

.viewMoreBtn {
  background: none;
  width: 100%;
  border: none;
  color: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .flightDetail {
    gap: 20px;
  }

  .departureData,
  .returnData {
    width: fit-content;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  .departureData span,
  .returnData span {
    width: fit-content;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
  }

  .viewMoreBtn {
    margin-bottom: 20px;
  }
}
