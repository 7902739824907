.Div {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  border: 1px solid rgb(220, 220, 220);
  background-color: white;
}

.Code {
  width: 15%;
}

.Digits {
  width: 85%;
  padding-left: 15px;
}

.Code,
.Digits {
  font-size: 14.5px;
  font-weight: 400;
  outline: none;
  background-color: transparent;
  border: none;
}

.Options {
  list-style-type: none;
  padding: 0;
  position: absolute;
  left: 0;
  height: 300px;
  width: 100%;
  overflow: scroll;
  background-color: white;
  margin-top: 15px;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 10px;
  z-index: 1000;
}

.Option {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 10px;
  align-items: center;
}

.Option:hover {
  background-color: #d1dbd5;
  color: #1a4d2e;
}

.Digits {
  border-left: 1px solid rgb(200, 200, 200);
}

@media screen and (max-width: 991px) {
  .Code {
    width: 20%;
  }

  .Digits {
    width: 80%;
    padding-left: 15px;
  }
}

.Div {
  position: relative;
}

.Disabled {
  background-color: #f0f0f0;
}

.DisabledInput {
  color: #000;
  cursor: not-allowed;
  pointer-events: none; /* Disable all mouse interactions */
}
