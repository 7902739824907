.Section {
  padding: 100px 0;
  /* background-image: url("./bg-img.png"); */
  background-color: var(--primary-color) !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.Card {
  width: 32%;
  border-radius: 10px;
  padding: 0px 0px;
  transition-duration: 0.3s;
}

/* .Card:hover {
  background-color: var(--primary-color-dark);
  -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
} */

.NumberDiv {
  width: fit-content;
  height: 40px;
  width: 40px;
  padding: 15px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 7.5px;
}

.Number {
  text-align: center;
  margin: 0 auto;
  font-size: 17px;
  font-weight: 800;
}

.Content {
  padding-left: 10px;
}

.Title {
  width: 100%;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--primary-color);
  text-align: left;
  margin-bottom: 15px;
  margin-top: 8px;
}

.Text {
  font-size: 17px;
  margin: 0;
  color: black;
  text-align: left;
  font-weight: 400;
}

@media only screen and (max-width: 991.5px) {
  .Section {
    padding: 100px 0;
  }
  .Card {
    width: 95%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .Card:nth-of-type(3) {
    margin-bottom: 0px;
  }
  .Title {
    margin-bottom: 13px;
    font-size: 18px;
  }
  .Text {
    font-size: 18px;
  }
}
