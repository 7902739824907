.Btn {
  text-align: center;
  padding: 10px 30px;
  border-radius: 5px;
  text-transform: capitalize;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition-duration: 0.3s;
}

.Primary {
  color: white;
  background-color: var(--primary-color);
  border: 1px solid transparent;
}

.Primary:hover {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
}

.Primary:disabled {
  background-color: var(--primary-color);
  opacity: 0.6;
}

.Primary:disabled:hover {
  background-color: var(--primary-color);
  opacity: 0.6;
  border-color: var(--primary-color);
}

.PrimaryOutline {
  color: var(--primary-color);
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.PrimaryOutline:hover {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
  color: white;
}

.PrimaryOutline:disabled {
  background-color: var(--primary-color);
  opacity: 0.6;
}

.PrimaryOutline:disabled:hover {
  background-color: var(--primary-color);
  opacity: 0.6;
  border-color: var(--primary-color);
}

@media (max-width: 600px) {
}
