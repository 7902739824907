.InputDiv {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0 20px;
  border-radius: 5px;
  transition-duration: 0.3s;
  border: 1.5px solid rgb(220, 220, 220);
}

.Icon {
  width: 5%;
  margin-right: 20px;
  font-size: 15px;
}

.Input {
  width: 95%;
  background-color: transparent;
  border: none;
  font-size: 14.5px;
  outline: none;
  padding: 12px 0;
}

.InputDiv:hover,
.InputDiv:focus-within {
  border-color: var(--primary-color);
}

.Input::placeholder {
  color: rgb(150, 150, 150);
  font-size: 14px;
}

@media only screen and (max-width: 991px) {
  .InputDiv {
    width: 100%;
    margin-bottom: 10px;
  }
}
